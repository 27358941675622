import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('.c_units.a_edit.units-edit-page', () => {
  import('./internal/c_units__a_edit__units-edit-page').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.user_linked_devices_index_page', () => {
  import('./internal/user_linked_devices_index_page').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.user_profile_form, .user_notifications_form', () => {
  import('./internal/user_profile_form__user_notifications_form').then(({ default: Component }) => Component());
});
