/* global Worker */
import { nanoid } from 'nanoid';

const filterTreeWorkersQueue = new Map();
let currentFilterTreeWorkerId = null;

const terminateFilterTreeWorker = (workerId) => {
  if (!workerId) { return; }

  const worker = filterTreeWorkersQueue.get(workerId);
  if (!worker) { return; }

  worker.terminate();
  filterTreeWorkersQueue.delete(workerId);
};

const createHandleRebuildTreeWorkerMessage = (
  setFilteredItems,
  setSearching,
) => ({ data }) => {
  const { newItems, workerId } = data;

  if (workerId !== currentFilterTreeWorkerId) { return; }

  setFilteredItems(newItems);
  setSearching(false);
  terminateFilterTreeWorker(workerId);
};

export default async function defineFilteredItems(filter, args) {
  const [searchApi, setFilteredItems, setSearching, items, plainItemsIndex] = args;
  terminateFilterTreeWorker(currentFilterTreeWorkerId);
  const foundItemKeys = await searchApi.search(filter);

  const worker = new Worker(
    new URL('components/top-menu-filter/helpers/filterTreeWorker.js', import.meta.url),
  );

  worker.addEventListener(
    'message',
    createHandleRebuildTreeWorkerMessage(setFilteredItems, setSearching),
    false,
  );

  currentFilterTreeWorkerId = nanoid();
  filterTreeWorkersQueue.set(currentFilterTreeWorkerId, worker);

  worker.postMessage({
    items,
    plainItemsIndex,
    foundItemKeys,
    currentFilterTreeWorkerId,
  });
}
