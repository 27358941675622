import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('.daily-work-table', () => {
  import('./internal/daily-work-table').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.fuel-reports-table', () => {
  import('./internal/fuel-reports-table').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.machine-reports-gps-page', () => {
  import('./internal/machine-reports-gps-page').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.machine-reports-hourly_work-page', () => {
  import('./internal/machine-reports-hourly_work-page').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.machine-reports-weighings-page', () => {
  import('./internal/machine-reports-weighings-page').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.machines-maintenance-reports-table', () => {
  import('./internal/machines-maintenance-reports-table').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.movement-reports-table', () => {
  import('./internal/movement-reports-table').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('#report-date-time-picker', () => {
  import('./internal/report-date-time-picker').then(({ default: Component }) => Component());
});
