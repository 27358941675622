import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('.implements_edit_page, .implements_new_page', () => {
  import('./internal/implements_edit_page__implements_new_page').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.implements_table', () => {
  import('./internal/implements_table').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.non-linked-implements-container', () => {
  import('./internal/non-linked-implements-container').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.unavailable_implements_table', () => {
  import('./internal/unavailable_implements_table').then(({ default: Component }) => Component());
});
