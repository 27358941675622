/**
 * Modifies the styles of the list so that the popover remains user friendly during the search
 *
 * @param {HTMLElement} filterWrapperNode
 * @returns {null}
 */
const modifyFilterListStyles = (filterWrapperNode) => {
  if (filterWrapperNode === null) return;

  const filterListNode = filterWrapperNode.querySelector('.top-menu-filter__list');

  if (!filterListNode.offsetWidth) return;

  filterListNode.style.width = `${filterListNode.offsetWidth}px`;
  filterListNode.style.height = `${filterListNode.offsetHeight + 16}px`;
};

export default modifyFilterListStyles;
