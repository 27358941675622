import { uniq } from 'lodash-es';
import { ALL_GROUPS_KEY } from './prepareItems';

function getFolderDescendatKeys(plainItemsIndex, node) {
  if (node.type === 'group') return [];

  const plainItems = [...plainItemsIndex.values()];
  return plainItems
    .filter((item) => item.get('ancestorKeys').includes(node.key))
    .map((item) => item.get('key'));
}

export function defineHalfCheckedKeys(checkedKeys, plainItemsIndex) {
  const halfCheckedKeys = new Set();
  const checkedNodes = [...checkedKeys].map((key) => plainItemsIndex.get(key));
  const checkedNodeAncestorKeys = uniq(checkedNodes.flatMap((node) => node.get('ancestorKeys')));

  checkedNodeAncestorKeys.reverse().forEach((key) => {
    const ancestor = plainItemsIndex.get(key);

    const hasHalfCheckedChildren =
      ancestor.get('childrenKeys').some((childKey) => halfCheckedKeys.has(childKey));

    const childrenKeys = ancestor.get('childrenKeys');
    const checkedCount = childrenKeys.filter((childKey) => checkedKeys.has(childKey)).length;
    const hasSomeChildChecked = checkedCount > 0 && checkedCount < childrenKeys.length;

    if (hasHalfCheckedChildren || hasSomeChildChecked) {
      halfCheckedKeys.add(key);
    }
  });

  return halfCheckedKeys;
}

function onCheckNode(plainItemsIndex, node, currentTreeKeys) {
  const descendantKeys = getFolderDescendatKeys(plainItemsIndex, node);
  const { key: currentNodeKey, ancestorKeys } = node;

  const checkedKeys = new Set([
    ...currentTreeKeys.checked,
    ...descendantKeys,
    currentNodeKey,
  ]);

  if (ancestorKeys !== undefined) {
    ancestorKeys.slice(0).reverse().forEach((ancestorKey) => {
      if (checkedKeys.has(ancestorKey)) { return; }

      const ancestorChildrenKeys = plainItemsIndex.get(ancestorKey).get('childrenKeys');
      const skipAncestor = ancestorChildrenKeys.some((key) => !checkedKeys.has(key));

      if (skipAncestor) { return; }

      checkedKeys.add(ancestorKey);
    });
  }

  const halfCheckedKeys = defineHalfCheckedKeys(checkedKeys, plainItemsIndex);

  return {
    checked: checkedKeys,
    halfChecked: halfCheckedKeys,
  };
}

function onUncheckNode(plainItemsIndex, node, currentTreeKeys) {
  const descendantKeys = getFolderDescendatKeys(plainItemsIndex, node);
  const uncestorKeys = (node.ancestorKeys !== undefined) ? [...node.ancestorKeys] : [];

  const checkedKeysArr = currentTreeKeys.checked.filter((key) => (![
    ...descendantKeys,
    ...uncestorKeys,
    node.key,
  ].includes(key)));

  const checkedKeys = new Set(checkedKeysArr);
  const halfCheckedKeys = defineHalfCheckedKeys(checkedKeys, plainItemsIndex);

  return {
    checked: checkedKeys,
    halfChecked: halfCheckedKeys,
  };
}

export default function defineTreeKeys({
  plainItemsIndex,
  node,
  nodeIsChecked,
  currentTreeKeys,
  allGroupsKey = ALL_GROUPS_KEY,
}) {
  if (node.key === allGroupsKey) {
    return {
      checked: nodeIsChecked ? new Set([...plainItemsIndex.keys()]) : new Set([]),
      halfChecked: new Set([]),
    };
  }

  const func = nodeIsChecked ? onCheckNode : onUncheckNode;

  return func(plainItemsIndex, node, currentTreeKeys);
}
