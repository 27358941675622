import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('.alerts-show-page', () => {
  import('./internal/alerts-show-page').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.devise-form-login', () => {
  import('./internal/devise-form-login').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.users-index-page', () => {
  import('./internal/users-index-page').then(({ default: Component }) => Component());
});
