import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('.custom-fields-form', () => {
  import('./internal/custom-fields-form').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.scout-report-measurement-type-form', () => {
  import('./internal/scout-report-measurement-type-form').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.unit-form', () => {
  import('./internal/unit-form').then(({ default: Component }) => Component());
});
