/* eslint-disable react/prop-types, react/jsx-props-no-spreading */

import React from 'react';
import { Spin } from 'antd';

const Loading = () => (
  // Using delay 3000ms to avoid visual flattering.
  // Spinner shuld appear only on very slow internet.
  <div style={{ textAlign: 'center' }}>
    <Spin delay={3000} />
  </div>
);

export default Loading;
