import {
  set_current_groupables_path,
  set_show_all_groups_path,
} from 'js-routes/generated/routes';
import { ALL_GROUPS_KEY } from './prepareItems';
import prepareDataByDepthLevels from './prepareDataByDepthLevels';

/**
 * Defines the URL to apply the selected folders/groups
 *
 * @param {Set} checkedKeys
 * @param {Map} plainItemsIndex
 * @returns {string}
 */
export default function defineApplyUrl(checkedKeys, plainItemsIndex) {
  if (checkedKeys.has(ALL_GROUPS_KEY)) {
    return set_show_all_groups_path();
  }

  const {
    depthLevels,
    nodesGroupedByDepth,
  } = prepareDataByDepthLevels(checkedKeys, plainItemsIndex);

  const groupFolderIds = new Set();
  const fieldGroupIds = new Set();
  depthLevels.forEach((depth) => {
    nodesGroupedByDepth.get(depth).forEach((node) => {
      const skipAsChildren = node.get('ancestorIds').some(
        (folderId) => groupFolderIds.has(folderId),
      );

      if (skipAsChildren) { return; }

      if (node.get('type') === 'folder') {
        groupFolderIds.add(node.get('id'));
      } else {
        fieldGroupIds.add(node.get('id'));
      }
    });
  });

  return set_current_groupables_path({
    // eslint-disable-next-line camelcase
    group_folder_ids: [...groupFolderIds],
    // eslint-disable-next-line camelcase
    field_group_ids: [...fieldGroupIds],
  });
}
