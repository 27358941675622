import React from 'react';
import { bool, string } from 'prop-types';

const Item = ({
  hasSubfolders,
  title,
  hidden,
}) => (
  <span
    className={[
      'top-menu-filter__list-item',
      hasSubfolders ? 'top-menu-filter__list-item_has-subfolders' : '',
      hidden ? 'top-menu-filter__list-item_hidden' : '',
    ].join(' ')}
  >
    {title}
  </span>
);

Item.propTypes = {
  hasSubfolders: bool.isRequired,
  title: string.isRequired,
  hidden: bool,
};

Item.defaultProps = {
  hidden: false,
};

export default Item;
