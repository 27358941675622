import React,
{
  useCallback,
  useRef,
  useState,
} from 'react';
import I18n from 'i18n-js';
import {
  set_current_year_path,
  top_menu_field_groups_path,
  top_menu_machine_regions_path,
  set_current_production_cycles_path,
} from 'js-routes/generated/routes';
import {
  arrayOf, bool, number, oneOfType, shape, string,
} from 'prop-types';
import {
  Spin,
  Badge,
  Space,
  Button,
  Drawer,
  Select,
  Collapse,
  notification,
} from 'antd';
import { isOrchard, isMachinery } from 'helpers/userSessionSettings';
import { camelizeKeys } from 'humps';
import axios from 'axios';
import { capitalize, isEmpty, isEqual } from 'lodash-es';
import FaIcon from 'components/fa-icon';
import {
  CarOutlined,
  DownOutlined,
  WarningFilled,
  GatewayOutlined,
} from '@ant-design/icons';
import flattenTopMenuItemsToMap from 'helpers/flattenTopMenuItemsToMap';
import toggleBemModifier from 'helpers/toggle-bem-modifier';
import ProductionCyclesSettings from './nested/ProductionCyclesSettings';
import prepareItems, { ALL_GROUPS_KEY } from './helpers/prepareItems';
import preparedMachineryRegionsItems from './machinery-regions-helpers/prepareItems';
import initializeCheckedKeys from './helpers/initializeCheckedKeys';
import defineTreeKeys from './helpers/defineTreeKeys';
import defineApplyUrl from './helpers/defineApplyUrl';
import defineApplyRegionsUrl from './machinery-regions-helpers/defineApplyRegionsUrl';
import checkedKeysCompare from './helpers/checkedKeysCompare';
import FieldGroupsSettings from './nested/FieldGroupsSettings';
import MachineRegionsSettings from './nested/MachineRegionsSettings';
import usePostRequest from './hooks/usePostRequest';

const topMenuTitle = I18n.t(`top_menu_right_part.${isOrchard ? 'orchard_plots' : 'field_groups'}`);
const topMachineMenuTitle = I18n.t('top_menu_right_part.machine_regions');

const TopMenuFieldGroups = ({
  title,
  seasonsByYears,
  productionCycleLabel,
  yearLabel,
  machineRegionsTitle,
  settings: {
    currentYear,
    showAllGroups,
    showAllMachineRegions,
    currentProductionCycleIds,
    productionCyclesBySeasons,
  },
}) => {
  const { current: initSettings } = useRef({
    expandKeys: [],
    expandAll: false,
    expandAllRegions: true,
    expandedRegionsKeys: [],
    initialCheckedKeys: { checked: [], halfChecked: [] },
    initialRegionsCheckedKeys: { checked: [], halfChecked: [] },
  });

  const visible = false;
  const [fieldGroups, setFieldGroups] = useState([]);
  const [machineRegions, setMachineRegions] = useState([]);
  const [sendingRequest, setSendingRequest] = useState(false);
  const [sendingMachineRegionsRequest, setSendingMachineRegionsRequest] = useState(false);
  const [plainItemsIndex, setPlainItemsIndex] = useState(new Map());
  const [plainRegionsItemsIndex, setRegionsPlainItemsIndex] = useState(new Map());
  const [treeKeys, setTreeKeys] = useState(initSettings.initialCheckedKeys);
  const [regionsTreeKeys, setRegionsTreeKeys] = useState(initSettings.initialRegionsCheckedKeys);
  const [applyUrl, setApplyUrl] = useState(null);
  const [applyRegionsUrl, setApplyRegionsUrl] = useState(null);
  const [noEvalableRegions, setNoEvalableRegions] = useState(false);
  const [drawerHeight, setDrawerHeight] = useState(0);
  const postRequest = usePostRequest();

  const loadMachineRegionsData = useCallback(async () => {
    if (!isEmpty(machineRegions) || sendingMachineRegionsRequest) { return; }

    setSendingMachineRegionsRequest(true);

    const url = top_menu_machine_regions_path();
    const data = await postRequest(url);
    const { items, expandAll, expandKeys } = camelizeKeys(data);
    if (isEmpty(items)) {
      setNoEvalableRegions(true);
      setSendingMachineRegionsRequest(false);
      return;
    }
    const machineryRegionsFmt = preparedMachineryRegionsItems(items || []);
    const plainMapIndex = flattenTopMenuItemsToMap(machineryRegionsFmt);
    setMachineRegions(items);
    initSettings.expandAllRegions = expandAll;
    initSettings.expandedRegionsKeys = expandKeys;
    initSettings.initialRegionsCheckedKeys =
      initializeCheckedKeys(plainMapIndex, showAllMachineRegions);

    setRegionsTreeKeys(initSettings.initialRegionsCheckedKeys);
    setRegionsPlainItemsIndex(plainMapIndex);
    setMachineRegions(machineryRegionsFmt);

    setSendingMachineRegionsRequest(false);
    setNoEvalableRegions(false);
  }, [
    postRequest,
    machineRegions,
    showAllMachineRegions,
    sendingMachineRegionsRequest,
    initSettings]);

  const loadFieldGroupsData = useCallback(async () => {
    if (!isEmpty(fieldGroups) || sendingRequest) { return; }

    setSendingRequest(true);

    const url = top_menu_field_groups_path();
    const data = await postRequest(url);
    const { items, expandAll, expandKeys } = camelizeKeys(data);
    const fieldGroupsFmt = prepareItems(items || [], showAllGroups);
    const plainMapIndex = flattenTopMenuItemsToMap(fieldGroupsFmt);

    initSettings.expandAll = expandAll;
    initSettings.expandKeys = expandKeys;
    initSettings.initialCheckedKeys = initializeCheckedKeys(plainMapIndex, showAllGroups);

    setTreeKeys(initSettings.initialCheckedKeys);
    setPlainItemsIndex(plainMapIndex);
    setFieldGroups(fieldGroupsFmt);

    setSendingRequest(false);
  }, [
    postRequest,
    fieldGroups,
    initSettings,
    sendingRequest,
    showAllGroups,
  ]);

  const loadMenuData = useCallback(async () => {
    loadFieldGroupsData();
    loadMachineRegionsData();
  }, [
    loadFieldGroupsData,
    loadMachineRegionsData,
  ]);

  const onCheck = (_, { checked: nodeIsChecked, node }) => {
    const newTreeKeys = defineTreeKeys({
      plainItemsIndex,
      node,
      nodeIsChecked,
      currentTreeKeys: treeKeys,
      allGroupsKey: ALL_GROUPS_KEY,
    });

    const newCheckedArr = [...newTreeKeys.checked];
    const newHalfCheckedArr = [...newTreeKeys.halfChecked];

    setTreeKeys({
      checked: newCheckedArr,
      halfChecked: newHalfCheckedArr,
    });

    const { initialCheckedKeys: { checked, halfChecked } } = initSettings;
    const isCheckedEqual = checkedKeysCompare(checked, newCheckedArr);
    const isHalfCheckedEqual = checkedKeysCompare(halfChecked, newHalfCheckedArr);

    const initialSelectionWasModified = !isCheckedEqual || !isHalfCheckedEqual;

    setApplyUrl(
      initialSelectionWasModified
        ? defineApplyUrl(newTreeKeys.checked, plainItemsIndex)
        : null,
    );
  };

  const onCheckRegion = (_, { checked: nodeIsChecked, node }) => {
    const newTreeKeys = defineTreeKeys({
      plainItemsIndex: plainRegionsItemsIndex,
      node,
      nodeIsChecked,
      currentTreeKeys: regionsTreeKeys,
    });

    const newCheckedArr = [...newTreeKeys.checked];
    const newHalfCheckedArr = [...newTreeKeys.halfChecked];

    setRegionsTreeKeys({
      checked: newCheckedArr,
      halfChecked: newHalfCheckedArr,
    });

    const { initialRegionsCheckedKeys: { checked, halfChecked } } = initSettings;
    const isCheckedEqual = checkedKeysCompare(checked, newCheckedArr);
    const isHalfCheckedEqual = checkedKeysCompare(halfChecked, newHalfCheckedArr);

    const initialSelectionWasModified = !isCheckedEqual || !isHalfCheckedEqual;

    setApplyRegionsUrl(
      initialSelectionWasModified
        ? defineApplyRegionsUrl(newTreeKeys.checked, plainRegionsItemsIndex)
        : null,
    );
  };

  const fieldGroupsFilterClasses = toggleBemModifier(
    'top-menu-field-group-filter__filter',
    'loading',
    sendingRequest,
  );

  const machineRegionssFilterClasses = toggleBemModifier(
    'top-menu-field-group-filter__regions_filter',
    'loading',
    sendingMachineRegionsRequest,
  );

  const [open, setOpen] = useState(false);
  const [sendingChanges, setSendingChanges] = useState(false);
  const [currentSeason, setCurrentSeason] = useState(currentYear);
  const [collapseActiveKey, setCollapseActiveKey] = useState(1);
  const [checkedProdCycleKeys, setCheckedProdCycleKeys] = useState(currentProductionCycleIds);

  const { Panel } = Collapse;

  const showDrawer = () => { setOpen(true); };

  const onClose = () => {
    setOpen(false);
    setCurrentSeason(currentYear);
    setCheckedProdCycleKeys(currentProductionCycleIds);

    setTreeKeys(initSettings.initialCheckedKeys);
    setRegionsTreeKeys(initSettings.initialRegionsCheckedKeys);
    setApplyUrl(null);
    setApplyRegionsUrl(null);
  };

  const openCollapseActiveKey = (keyNumber) => {
    setCollapseActiveKey(keyNumber);
  };

  const seasonSelectList = Object.entries(seasonsByYears)
    .map(([year, name]) => ({ value: year, label: `${name} (${year})` }))
    .reverse();

  const notificationMessage = (message) => {
    notification.warning({
      message,
      style: { backgroundColor: '#FFF3DD', width: 'auto' },
      placement: 'bottom',
      duration: 5,
      icon: <WarningFilled style={{ color: '#C17E19' }} />,
    });
  };

  const validateParams = () => {
    let isValid = true;

    if (checkedProdCycleKeys.length === 0) {
      notificationMessage(I18n.t('top_menu_right_part.please_select_product_cycle'));
      isValid = false;
    }

    if (treeKeys.checked.length === 0) {
      notificationMessage(I18n.t(`top_menu_right_part.please_select_${isOrchard ? 'orchard_plot' : 'field_group'}`));
      isValid = false;
    }

    if ((regionsTreeKeys.checked.length === 0) && isMachinery && !noEvalableRegions) {
      notificationMessage(I18n.t('top_menu_right_part.please_select_machine_region'));
      isValid = false;
    }

    return isValid;
  };

  const changedFormParams = () => (
    currentSeason.toString() !== currentYear.toString()
    || !isEqual(checkedProdCycleKeys.sort(), currentProductionCycleIds.sort())
    || applyUrl || applyRegionsUrl
  );

  const saveChanges = async () => {
    if (!validateParams()) return;
    if (!changedFormParams()) return;

    setSendingChanges(true);

    try {
      if (currentSeason.toString() !== currentYear.toString()) {
        const currentSeasonUrl = set_current_year_path(currentSeason);
        await axios.get(currentSeasonUrl);
      }

      if (applyUrl) {
        const currentFieldGroupsUrl = applyUrl;
        await axios.get(currentFieldGroupsUrl);
      }

      if (applyRegionsUrl) {
        const currentMachineRegionsUrl = applyRegionsUrl;
        await axios.get(currentMachineRegionsUrl);
      }

      if (!isEqual(checkedProdCycleKeys, currentProductionCycleIds)) {
        const currentProductionCycleUrl = set_current_production_cycles_path(
          // eslint-disable-next-line camelcase
          { production_cycle_ids: checkedProdCycleKeys },
        );
        await axios.get(currentProductionCycleUrl);
      }

      // eslint-disable-next-line no-undef
      window.location.reload();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setSendingChanges(false);
    }
  };

  const getDrawerHeight = () => {
    // eslint-disable-next-line no-undef
    const drawerBody = document.querySelector('.ant-drawer-body');
    const drawerBodyHeight = drawerBody?.clientHeight;

    setDrawerHeight(drawerBodyHeight);
  };

  const drawerStyles = {
    header: {
      borderLeft: '5px solid #8354d6',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      padding: '16px 12px',
    },
    footer: {
      textAlign: 'right',
    },
  };

  const fieldGroupsPanelName = () => (
    <b>
      {I18n.t(`activerecord.attributes.field.${isOrchard ? 'orchard_plot' : 'field_group'}`, { count: 5 })}
      <Badge
        size="small"
        count={treeKeys.checked.filter((key) => key.startsWith('g_')).length}
        showZero
        color="#c6e6ff"
        offset={[6, 0]}
        style={{
          boxShadow: 'none',
          color: '#0172cc',
          borderRadius: '10px',
          minWidth: '30px',
          minHeight: '20px',
          fontWeight: '600',
          lineHeight: '19px',
        }}
      />
    </b>
  );

  const productionCyclesPanelName = () => (
    <>
      <b>{capitalize(I18n.t('production_cycles.index.production_cycles'))}</b>
      <Badge
        size="small"
        count={checkedProdCycleKeys.length}
        showZero
        color="#fed7de"
        offset={[6, 0]}
        style={{
          boxShadow: 'none',
          color: '#bb485d',
          borderRadius: '10px',
          minWidth: '30px',
          minHeight: '20px',
          fontWeight: '600',
          lineHeight: '19px',
        }}
      />
    </>
  );

  const machineRegionsPanelName = () => (
    <b>
      {I18n.t('top_menu_right_part.machine_regions')}
      <Badge
        size="small"
        count={regionsTreeKeys.checked.filter((key) => key.startsWith('r_')).length}
        showZero
        color="#fee4ae"
        offset={[6, 0]}
        style={{
          boxShadow: 'none',
          color: '#98660e',
          borderRadius: '10px',
          minWidth: '30px',
          minHeight: '20px',
          fontWeight: '600',
          lineHeight: '19px',
        }}
      />
    </b>
  );

  const machineRegionsPanelContent = () => {
    if (noEvalableRegions) {
      return (<p>{I18n.t('top_menu_right_part.no_available_machine_regions')}</p>);
    }

    return (
      <MachineRegionsSettings
        items={machineRegions}
        checkedKeys={regionsTreeKeys}
        onCheck={onCheckRegion}
        plainItemsIndex={plainRegionsItemsIndex}
        visible={visible}
        expandAll={initSettings.expandAllRegions}
        expandKeys={initSettings.expandedRegionsKeys}
        drawerHeight={drawerHeight}
      />
    );
  };

  const showGroupTitle = parseInt(title, 10) > 0;

  return (
    (
      <div className="top-menu-field-groups">
        <Button
          className="top-menu-field-groups__toggle-btn"
          onClick={showDrawer}
          onMouseOver={loadMenuData}
          type="link"
          block
        >
          <div
            style={{ display: 'inline-block' }}
            onClick={() => openCollapseActiveKey(1)}
            onKeyDown={() => openCollapseActiveKey(1)}
            role="button"
            tabIndex={0}
          >

            <GatewayOutlined
              title={title}
              className="show-group-icon"
              style={{ verticalAlign: 'middle', marginRight: '0px' }}
            />
            {showGroupTitle && (
              <span className="show-group-title" title={title}>{topMenuTitle}</span>
            )}

            <Badge
              size="small"
              title={topMenuTitle}
              count={title.length > 12 ? title.slice(0, 9).concat('...') : title} // we take max 12 symbols
              // @ts-ignore
              id="field-groups-badge"
              showZero
              color="#0071cd"
              style={{ boxShadow: 'none', marginLeft: '6px' }}
            />

          </div>

          <Badge
            size="small"
            title={I18n.t('top_menu_right_part.production_cycles')}
            count={productionCycleLabel}
            // @ts-ignore
            id="production-cycles-badge"
            showZero
            color="#bb485d"
            style={{ boxShadow: 'none', marginLeft: '6px' }}
            // @ts-ignore
            onClick={() => openCollapseActiveKey(2)}
          />

          <Badge
            size="small"
            title={I18n.t('top_menu_right_part.current_season')}
            count={yearLabel}
            // @ts-ignore
            id="season-badge"
            overflowCount={9999}
            showZero
            color="#8354d6"
            style={{ boxShadow: 'none', marginLeft: '6px' }}
            // @ts-ignore
            onClick={() => openCollapseActiveKey(1)}
          />

          {isMachinery && (
            <div
              style={{ display: 'inline-block', float: 'right' }}
              onClick={() => openCollapseActiveKey(3)}
              onKeyDown={() => openCollapseActiveKey(3)}
              role="button"
              tabIndex={-1}
            >

              <CarOutlined
                title={machineRegionsTitle}
                className="show-group-icon"
                style={{ marginLeft: '15px', marginRight: '0px', verticalAlign: 'middle' }}
              />

              <Badge
                size="small"
                title={topMachineMenuTitle}
                count={machineRegionsTitle.length > 12 ? machineRegionsTitle.slice(0, 9).concat('...') : machineRegionsTitle} // we take max 12 symbols
                id="machinery-regions-badge"
                showZero
                color="#0071cd"
                style={{ boxShadow: 'none', marginLeft: '6px' }}
              />

              <FaIcon
                className="top-menu-field-groups__caret-icon"
                icon={(visible ? 'caret-up' : 'caret-down')}
                modifier="fas"
              />

            </div>
          )}

        </Button>

        <Drawer
          className="top-menu-drawer"
          closable
          // @ts-ignore
          styles={drawerStyles}
          placement="right"
          afterOpenChange={getDrawerHeight}
          onClose={onClose}
          open={open}
          zIndex={1032}
          footer={(
            <Space className="top-menu-footer">
              <Button
                type="primary"
                loading={sendingChanges}
                onClick={saveChanges}
                disabled={!changedFormParams()}
              >
                {I18n.t('apply')}
              </Button>
            </Space>
          )}
          extra={(
            <Space id="season-panel" className="top-menu-field-group-filter__season-panel">
              <span><b>{I18n.t('top_menu_right_part.current_season')}</b></span>
              <Select
                className="top-menu-field-group-filter__season-select"
                id="season-select"
                showSearch
                defaultValue={currentSeason.toString()}
                value={currentSeason.toString()}
                onChange={(value) => setCurrentSeason(value)}
                filterOption={
                  (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={seasonSelectList}
              />
            </Space>
          )}
        >
          <Collapse
            accordion
            expandIconPosition="end"
            activeKey={collapseActiveKey}
            // eslint-disable-next-line react/no-unstable-nested-components
            expandIcon={({ isActive }) => (!isActive && <DownOutlined style={{ marginRight: '0px' }} />)}
            ghost
          >
            <Panel
              header={fieldGroupsPanelName()}
              className="top-menu-field-group-filter__header top-menu-field-group-filter__field-groups"
              // @ts-ignore
              onClick={() => openCollapseActiveKey(1)}
              key="1"
              id="field-groups-panel"
            >
              <div className={fieldGroupsFilterClasses}>
                {isEmpty(fieldGroups) ? <Spin size="large" /> : (
                  <FieldGroupsSettings
                    items={fieldGroups}
                    checkedKeys={treeKeys}
                    onCheck={onCheck}
                    plainItemsIndex={plainItemsIndex}
                    visible={visible}
                    expandAll={initSettings.expandAll}
                    expandKeys={initSettings.expandKeys}
                    drawerHeight={drawerHeight}
                  />
                )}
              </div>
            </Panel>

            <Panel
              header={productionCyclesPanelName()}
              className="top-menu-field-group-filter__header top-menu-field-group-filter__production-cycles"
              // @ts-ignore
              onClick={() => openCollapseActiveKey(2)}
              key="2"
              id="production-cycles-panel"
            >
              <div className="top-menu-field-groups__production-cycle-filter">
                <ProductionCyclesSettings
                  currentYear={currentYear}
                  currentSeason={currentSeason}
                  productionCyclesBySeasons={productionCyclesBySeasons}
                  checkedProdCycleKeys={checkedProdCycleKeys}
                  setCheckedProdCycleKeys={setCheckedProdCycleKeys}
                  drawerHeight={drawerHeight}
                />
              </div>
            </Panel>

            {isMachinery && (
              <Panel
                header={machineRegionsPanelName()}
                className="top-menu-field-group-filter__header top-menu-field-group-filter__machine-regions"
                // @ts-ignore
                onClick={() => openCollapseActiveKey(3)}
                key="3"
                id="machine-regions-panel"
              >
                <div className={machineRegionssFilterClasses}>
                  {(isEmpty(machineRegions) && sendingMachineRegionsRequest) ? <Spin size="large" /> : machineRegionsPanelContent()}
                </div>
              </Panel>
            )}
          </Collapse>
        </Drawer>
      </div>
    )
  );
};

TopMenuFieldGroups.propTypes = {
  title: string,
  machineRegionsTitle: string,
  settings: shape({
    availableYears: arrayOf(number),
    currentYear: number,
    showAdditionalObjects: bool,
    showAllGroups: bool,
    winterMode: bool,
    currentProductionCycleIds: arrayOf(number),
  }),
  seasonsByYears: shape({}).isRequired,
  productionCyclesBySeasons: arrayOf(shape({})),
  productionCycleLabel: oneOfType([string, number]).isRequired,
  yearLabel: oneOfType([string, number]).isRequired,
  showAllMachineRegions: bool,
};

TopMenuFieldGroups.defaultProps = {
  title: ' - ',
  machineRegionsTitle: ' - ',
  settings: {},
  productionCyclesBySeasons: [],
  showAllMachineRegions: false,
};

export default TopMenuFieldGroups;
