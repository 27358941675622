import SearchApi, { INDEX_MODES } from 'js-worker-search';

export default function prepareSearchApi(plainItemsIndex) {
  const api = new SearchApi({
    indexMode: INDEX_MODES.ALL_SUBSTRINGS,
    caseSensitive: false,
    matchAnyToken: false,
  });

  plainItemsIndex.forEach((item) => {
    api.indexDocument(item.get('key'), item.get('title'));
  });

  return api;
}
