import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('.top-menu__work-time-registration', () => {
  import('./internal/top-menu__work-time-registration').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('#dashboard-map', () => {
  import('./internal/dashboard-map').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('#dashboards-monitoring-datepicker', () => {
  import('./internal/dashboards-monitoring-datepicker').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.count_group_crop_value', () => {
  import('./internal/count_group_crop_value').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.show-empty-applicable', () => {
  import('./internal/empty-applicable-block').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.left-shared-menu', () => {
  import('./internal/left-shared-menu').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.note-photos-form', () => {
  import('./internal/note-photos-form').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.protected-documents-form', () => {
  import('./internal/protected-documents-form').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.ransack-filter', () => {
  import('./internal/ransack-filter').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.notifications', () => {
  import('./internal/restricted-message').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.scout-reports-widget-map', () => {
  import('./internal/scout-reports-map').then(({ default: Component }) => Component());
});
