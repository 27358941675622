import { cloneDeep } from 'lodash-es';
import recursivlyModifyTree from 'helpers/array/recursivlyModifyTree';

export default function prepareItems(items) {
  const clonedItems = cloneDeep(items || []);
  const updateItem = (item) => {
    // eslint-disable-next-line no-param-reassign
    item.ancestorKeys = [...(item.ancestorIds.map((id) => `r_${id}`))];
  };

  recursivlyModifyTree({
    tree: clonedItems,
    callback: updateItem,
  });

  return clonedItems;
}
