import $ from 'jquery'; // eslint-disable-line no-restricted-imports
import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('.issue-wh-documents-show-page', () => {
  $('[data-toggle="popover"]').popover();
});

onElelementExistRunOnce('.receipt-wh-documents-show-page', () => {
  $('[data-toggle="popover"]').popover();
});

onElelementExistRunOnce('.transfer-wh-documents-show-page', () => {
  $('[data-toggle="popover"]').popover();
});
