/* global window */

import $ from 'jquery'; // eslint-disable-line no-restricted-imports
import onElementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElementExistRunOnce('#redirect-url', () => {
  const url = $('#redirect-url').data('url');
  console.log(`Redirecting to: ${url}`); // eslint-disable-line no-console
  window.location = url;
});
