import { Notifier } from '@airbrake/browser';

import {
  environmentName, userId, userEmail,
  isDevelopment, currentTenant, serverTime,
} from 'helpers/userSessionSettings';

const airbrake = new Notifier({
  projectId: 132223,
  projectKey: '35a0a90279976f18827aea2a10559d8d',
  environment: environmentName,
  remoteConfig: false,
});

/* eslint-disable no-param-reassign */
airbrake.addFilter((notice) => {
  notice.params.userId = userId;
  notice.params.userEmail = userEmail;
  notice.params.tenant = currentTenant;
  notice.params.serverTime = serverTime;
  notice.params.clientTime = new Date().toUTCString();

  // Skip sending errors in development environment.
  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.warn('Airbrake notice skipped (we are in development env).');
    // For debug airbrake notice uncomment:
    // console.log(notice);
    return null;
  }

  return notice;
});
/* eslint-enable no-param-reassign */

export default airbrake;
