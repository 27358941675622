import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('.field_scout_reports_index_page', () => {
  import('./internal/fancybox').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.field-scout-report-show-page', () => {
  import('./internal/fancybox').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.field_scout_reports_new_page', () => {
  import('./internal/fancybox').then(({ default: Component }) => Component());
});
