import React from 'react';
import { string, bool, oneOf } from 'prop-types';
import { Space } from 'antd';

const FaIcon = ({
  icon,
  modifier,
  text,
  right,
  className,
  size,
}) => {
  const classNames = [
    `fa-${icon}`,
    modifier,
    className,
    size && `fa-${size}`,
  ].filter(Boolean).join(' ');

  return (
    <Space style={{ flexDirection: right ? 'row-reverse' : 'row' }}>
      <i className={classNames} />
      {text}
    </Space>
  );
};

FaIcon.propTypes = {
  icon: string.isRequired,
  text: string,
  className: string,
  right: bool,
  size: string,
  modifier: oneOf(['fa', 'fas', 'far', 'fad', 'fab', 'fal']),
};

FaIcon.defaultProps = {
  text: '',
  className: '',
  right: false,
  size: null,
  modifier: 'fa',
};

export default FaIcon;
