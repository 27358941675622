import { userLocale } from 'helpers/userSessionSettings';

import bg from 'antd/es/locale/bg_BG';
import cs from 'antd/es/locale/cs_CZ';
import da from 'antd/es/locale/da_DK';
import de from 'antd/es/locale/de_DE';
import enGB from 'antd/es/locale/en_GB';
import en from 'antd/es/locale/en_US';
import es from 'antd/es/locale/es_ES';
import et from 'antd/es/locale/et_EE';
import fr from 'antd/es/locale/fr_FR';
import hu from 'antd/es/locale/hu_HU';
import lt from 'antd/es/locale/lt_LT';
import lv from 'antd/es/locale/lv_LV';
import nl from 'antd/es/locale/nl_NL';
import pl from 'antd/es/locale/pl_PL';
import ptBR from 'antd/es/locale/pt_BR';
import ro from 'antd/es/locale/ro_RO';
import ru from 'antd/es/locale/ru_RU';
import sk from 'antd/es/locale/sk_SK';
import uk from 'antd/es/locale/uk_UA';
import uz from 'antd/es/locale/uz_UZ';

const locale = () => {
  const LOCALE_MAPPING = {
    bg,
    cs,
    da,
    de,
    'en-GB': enGB,
    en,
    es,
    et,
    fr,
    hu,
    lt,
    lv,
    nl,
    pl,
    'pt-BR': ptBR,
    ro,
    ru,
    sk,
    uk,
    uz,
  };

  return LOCALE_MAPPING[userLocale] || en;
};

export default locale;
