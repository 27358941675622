import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('#additional-objects-map', () => {
  import('./internal/map-view').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.additional_objects_new_page', () => {
  import('./internal/new').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.additional-objects-show-page', () => {
  import('./internal/show').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.additional-objects-index-page', () => {
  import('./internal/table').then(({ default: Component }) => Component());
});
