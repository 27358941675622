import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('.weather_stations_index_page', () => {
  import('./internal/index-page').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('#weather_station_map', () => {
  import('./internal/weather-station-map').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('#wmo_weather_station_map', () => {
  import('./internal/wmo-weather-station-map').then(({ default: Component }) => Component());
});
