import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('.field-current-ndvi', () => {
  import('./internal/field-current-ndvi').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.field-dashboards-monitoring-page', () => {
  import('./internal/field-dashboards-monitoring-page').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.machinery_tasks_list', () => {
  import('./internal/machinery_tasks_list').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.widget_field_scout_reports', () => {
  import('./internal/widget_field_scout_reports').then(({ default: Component }) => Component());
});
