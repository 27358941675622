import I18n from 'i18n-js';
import { cloneDeep } from 'lodash-es';
import { isOrchard } from 'helpers/userSessionSettings';
import recursivlyModifyTree from 'helpers/array/recursivlyModifyTree';

export const ALL_GROUPS_KEY = 'all-groups';

export default function prepareItems(items, showAllGroups) {
  const clonedItems = cloneDeep(items || []);
  const updateItem = (item) => {
    // eslint-disable-next-line no-param-reassign
    item.ancestorKeys = [ALL_GROUPS_KEY, ...(item.ancestorIds.map((id) => `f_${id}`))];
  };

  recursivlyModifyTree({
    tree: clonedItems,
    callback: updateItem,
  });

  const allGroupsItem = {
    active: showAllGroups,
    ancestorKeys: [],
    children: clonedItems,
    depth: 0,
    hasSubfolders: true,
    id: 0,
    key: ALL_GROUPS_KEY,
    title: I18n.t(isOrchard ? 'all_orchard_plots' : 'all_groups'),
    type: 'universal',
  };

  return [allGroupsItem];
}
