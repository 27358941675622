/* global window */

import $ from 'jquery'; // eslint-disable-line no-restricted-imports
import onElementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElementExistRunOnce('.iframe-container', () => {
  const el = $('.iframe-container');
  window.open(el.data('url'), el.data('frame'));
  if (el.data('timeout')) {
    setTimeout(() => { window.location.href = el.data('to'); }, el.data('timeout'));
  }
});
