import { useCallback } from 'react';
import axios from 'axios';

const usePostRequest = () => {
  const postRequest = useCallback(async (url) => {
    try {
      const { data } = await axios.post(url);
      return data;
    } catch {
      return {};
    }
  }, []);

  return postRequest;
};

export default usePostRequest;
