import $ from 'jquery'; // eslint-disable-line no-restricted-imports

/**
 * @param {string} selector  - jQuery selector
 * @param {function} callback - callback function
 */
const onElementExistRunOnce = (selector, callback) => {
  $(() => {
    if ($(selector).length > 0) { callback(); }
  });
};

export default onElementExistRunOnce;
