/**
 * Autofocus in the search input by conditions
 *
 * @param {HTMLElement} filterWrapperNode
 * @returns {null}
 */
const setSearchInputFocus = (filterWrapperNode) => {
  if (filterWrapperNode === null) return;

  const body = filterWrapperNode.closest('body');
  const searchInputNode = filterWrapperNode.querySelector('.top-menu-filter__search-input>input');

  if (body.clientWidth >= 1200) {
    setTimeout(() => searchInputNode.focus(), 100);
  }
};

export default setSearchInputFocus;
