/* eslint-disable react/jsx-props-no-spreading */

import React, { lazy, Suspense } from 'react';
import CWOConfigProvider from 'helpers/cwoConfigProvider';
import Loading from 'helpers/loading/loading';

const Component = lazy(() => import('components/agri-work-plan-agro-operation-covered-area'));

const CoveredAreaAgroOperation = (props) => (
  <CWOConfigProvider>
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  </CWOConfigProvider>
);

export default CoveredAreaAgroOperation;
