/* global location */

import $ from 'jquery'; // eslint-disable-line no-restricted-imports
import mergeURLSearchParams from 'helpers/url/mergeURLSearchParams';

$(() => {
  $('a[data-remember-location]').on('click', (e) => {
    e.preventDefault();

    const targetURL = new URL(e.target.href);
    const currentSearchParams = new URLSearchParams(location.search);
    const newSearchParams = mergeURLSearchParams(targetURL.searchParams, currentSearchParams);
    targetURL.search = newSearchParams.toString();

    location.href = targetURL.toString();
  });
});
