import { pick } from 'lodash-es';

const keys = [
  'active', 'ancestorIds', 'ancestorKeys',
  'depth', 'id', 'key', 'title', 'type',
];

function plain(notPlainArray, plainMap) {
  notPlainArray.forEach((item) => {
    const { children, key } = item;

    const pickedItemKeys = pick(item, keys);
    const childrenKeys = children.map(({ key: childKey }) => childKey);

    const elementMap = new Map([
      ['childrenKeys', childrenKeys],
      ...Object.entries(pickedItemKeys),
    ]);

    plainMap.set(key, elementMap);

    if (!Array.isArray(children)) { return; }

    plain(children, plainMap);
  });
}

/**
 * Flattens a nested array of elements and converts it
 * to a Map structure to improve performance when searching
 * for the required node. Makes sense on a large tree.
 *
 * @param {Array} items
 * @returns {Map}
 */
export default function flattenTopMenuItemsToMap(items) {
  const plainMap = new Map();

  plain(items, plainMap);

  return plainMap;
}
