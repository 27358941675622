import $ from 'jquery'; // eslint-disable-line no-restricted-imports

/**
 * @callback Callback
 * @param {number} index
 * @param {HTMLElement} element
 */

/**
 * @param {string} selector  - jQuery selector
 * @param {Callback} callback - callback function
 */
const onElementExistRunForEach = (selector, callback) => {
  $(() => {
    $(selector).each(callback);
  });
};

export default onElementExistRunForEach;
