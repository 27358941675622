import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('.shapes_uploads_check_page', () => {
  import('./internal/check').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.shapes_upload_form', () => {
  import('./internal/new').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.shapes_upload_select_page', () => {
  import('./internal/select').then(({ default: Component }) => Component());
});
