import { defineHalfCheckedKeys } from './defineTreeKeys';

/**
 * Initializes the checked keys for the Tree component.
 *
 * @param {Map} plainItemsIndex
 * @param {boolean} showAllGroups
 * @returns {Object}
 */
export default function initializeCheckedKeys(plainItemsIndex, showAllGroups) {
  if (showAllGroups) {
    return {
      checked: [...plainItemsIndex.keys()],
      halfChecked: [],
    };
  }

  const allCheckedKeys = new Set();
  plainItemsIndex.forEach((item, key) => {
    if (!item.get('active')) { return; }

    allCheckedKeys.add(key);
  });

  const halfChecked = defineHalfCheckedKeys(allCheckedKeys, plainItemsIndex);

  return {
    checked: [...allCheckedKeys],
    halfChecked: [...halfChecked],
  };
}
