/* global document */

import $ from 'jquery'; // eslint-disable-line no-restricted-imports
import I18n from 'i18n-js';

$(() => {
  const disableAllDownloadBtn = () => {
    const $disabledDownloadBtn = $('.disable-download').not('.disabled');

    if ($disabledDownloadBtn.length > 0) {
      $disabledDownloadBtn.wrap("<div class='disable-download-tooltip-wrapper btn-group'></div>");
      $disabledDownloadBtn.addClass('disabled');

      $disabledDownloadBtn
        .parent('.disable-download-tooltip-wrapper')
        .tooltip({
          title: I18n.t('download_disabled'),
          container: 'body',
        });

      $disabledDownloadBtn
        .filter('.pull-right')
        .parent('.disable-download-tooltip-wrapper')
        .addClass('pull-right');
    }
  };

  disableAllDownloadBtn();
  $(document).on('init.dt', disableAllDownloadBtn);
});
