export default function prepareDataByDepthLevels(checkedKeys, plainItemsIndex) {
  const nodesGroupedByDepth = new Map();
  const depthLevels = new Set();
  [...checkedKeys].reverse().forEach((key) => {
    const checkedNode = plainItemsIndex.get(key);
    const depth = checkedNode.get('depth');
    if (!depth) { return; }
    if (!nodesGroupedByDepth.has(depth)) {
      nodesGroupedByDepth.set(depth, new Set());
    }

    depthLevels.add(depth);
    nodesGroupedByDepth.get(depth).add(checkedNode);
  });

  return { depthLevels, nodesGroupedByDepth };
}
