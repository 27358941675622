import I18n from 'i18n-js';

export default function setLocale(
  locale, i18nLocaleJsonData, pluralizationRules = I18n.pluralization.default,
) {
  // I18n-js generate JSON files with next format:
  // {"en":{"test":"Test"}}
  // Set localization data:
  I18n.translations[locale] = i18nLocaleJsonData[locale];
  I18n.pluralization[locale] = pluralizationRules;
  // We have already merged fallbacks in i18nLocaleJsonData data.
  // So, no need to use js-fallbacks.
  I18n.fallbacks = false;

  // Switch i18n-js locale.
  I18n.defaultLocale = locale;
  I18n.locale = locale;
}
