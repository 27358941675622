/* eslint-disable react/jsx-props-no-spreading,react/prop-types */

import React, { lazy, Suspense } from 'react';

const Component = lazy(() => import('components/crop-rotation/nested/crop-cell'));

/*
  NOTE:
  1. We explicitly removed antd ConfigProvider here.
     CropCell components mounts to page as separate roots in <td> cells.
     For large tables (1000+) cells its became very slow.
     Removing ConfigProvider resolves the issue.
     But, the only good way would be to rewrite `crop_rotation/_table` erb view to antd Table.
  2. We remove default spinner-style Loading fallack.
     If page already slow and spinner start mounting, it will make everything even more luggish.
*/

const CropCell = ({ item, archiveLabel, url }) => (
  <Suspense fallback={null}>
    <Component item={item} archiveLabel={archiveLabel} url={url} />
  </Suspense>
);

export default CropCell;
