import React, {
  useCallback, useState, useMemo, useEffect, useRef,
} from 'react';
import {
  arrayOf, bool, number, object, oneOfType, string, shape, func, instanceOf,
} from 'prop-types';
import I18n from 'i18n-js';
import { isOrchard } from 'helpers/userSessionSettings';
import { Input, Spin, Tree } from 'antd';
import { debounce } from 'lodash-es';
import airbrakeClient from 'configuredAirbrakeClient';
import FaIcon from 'components/fa-icon';
import Item from 'components/top-menu-filter/nested/Item';
import modifyFilterListStyles from 'components/top-menu-filter/helpers/modifyFilterListStyles';
import setSearchInputFocus from 'components/top-menu-filter/helpers/setSearchInputFocus';
import prepareSearchApi from 'components/top-menu-filter/helpers/prepareSearchApi';
import defineFilteredItems from 'components/top-menu-field-groups/helpers/groupabble_setting_helpers';

const FieldGroupsSettings = ({
  checkedKeys,
  items,
  onCheck,
  onSelect,
  plainItemsIndex,
  selectedKeys,
  visible,
  expandAll,
  expandKeys,
  drawerHeight,
}) => {
  const debounceTimeout = plainItemsIndex.size > 10000 ? 500 : 100;
  const filterWrapperRef = useRef(null);

  const [searchValue, setSearchValue] = useState('');
  const [searching, setSearching] = useState(false);
  const [filteredItems, setFilteredItems] = useState(items);

  const searchApi = useMemo(() => prepareSearchApi(plainItemsIndex), [plainItemsIndex]);

  const settings = [
    searchApi, setFilteredItems, setSearching, items, plainItemsIndex,
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateFilteredItems = useCallback(debounce(async (filter) => {
    try {
      setSearching(true);
      defineFilteredItems(filter, settings);
    } catch (error) {
      airbrakeClient.notify({ error });
    }
  }, debounceTimeout), []);

  const onChangeSearchValue = useCallback(({ target }) => {
    const filter = target.value;
    setSearchValue(filter);

    if (!filter) { setFilteredItems(items); return; }

    updateFilteredItems(filter);
  }, [updateFilteredItems, items]);

  useEffect(() => {
    if (!visible) { return; }

    modifyFilterListStyles(filterWrapperRef.current);
    setSearchInputFocus(filterWrapperRef.current);
  }, [visible]);

  const checkable = onCheck instanceof Function;
  const selectable = onSelect instanceof Function;

  const searchIcon = searching ? <Spin size="small" /> : <FaIcon modifier="fal" icon="search" />;

  const [parentHeight, setParentHeight] = useState(0);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const productionCyclesElement = document.querySelector('.top-menu-field-group-filter__production-cycles .ant-collapse-header');
    const productionCyclesHeight = productionCyclesElement?.clientHeight || 0;
    // eslint-disable-next-line no-undef
    const machineRegionsElement = document.querySelector('.top-menu-field-group-filter__machine-regions .ant-collapse-header');
    const machineRegionsHeight = machineRegionsElement?.clientHeight || 0;
    const drawerHeaderHeight = 160;

    setParentHeight(
      drawerHeight - drawerHeaderHeight - productionCyclesHeight - machineRegionsHeight,
    );
  }, [drawerHeight]);

  return (
    <div className="top-menu-field-group-filter" ref={filterWrapperRef}>
      <p>{I18n.t(`top_menu_right_part.${isOrchard ? 'work_with_orchard_plots' : 'work_with_field_groups'}`)}</p>
      <div className="top-menu-field-group-filter__search-container">
        <Input
          onChange={onChangeSearchValue}
          placeholder={I18n.t('search')}
          value={searchValue}
          suffix={searchIcon}
        />
      </div>

      <Tree
        checkable={checkable}
        checkedKeys={checkedKeys}
        checkStrictly
        defaultExpandAll={expandAll}
        defaultExpandedKeys={expandKeys}
        height={parentHeight}
        onCheck={onCheck}
        onSelect={onSelect}
        rootClassName="top-menu-field-group-filter__list"
        selectable={selectable}
        selectedKeys={selectedKeys}
        // @ts-ignore
        titleRender={Item}
        treeData={filteredItems}
      />
    </div>
  );
};

FieldGroupsSettings.propTypes = {
  checkedKeys: shape({
    checked: arrayOf(string),
    halfChecked: arrayOf(string),
  }),
  items: arrayOf(shape({
    active: bool,
    ancestorIds: arrayOf(number),
    // eslint-disable-next-line react/forbid-prop-types
    children: arrayOf(object),
    depth: number,
    hasSubfolders: bool,
    id: oneOfType([number, string]),
    key: string,
    title: string,
    type: string,
  })),
  onCheck: func,
  onSelect: func,
  plainItemsIndex: instanceOf(Map),
  selectedKeys: arrayOf(string),
  visible: bool.isRequired,
  expandAll: bool,
  expandKeys: arrayOf(string),
  drawerHeight: number.isRequired,
};

FieldGroupsSettings.defaultProps = {
  checkedKeys: null,
  items: [],
  onCheck: null,
  onSelect: null,
  selectedKeys: [],
  plainItemsIndex: new Map(),
  expandAll: true,
  expandKeys: [],
};

export default FieldGroupsSettings;
