import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('.fuel-station-form', () => {
  import('./internal/form').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.fuel-station-show-page', () => {
  import('./internal/show').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.fuel-station-index-page', () => {
  import('./internal/table').then(({ default: Component }) => Component());
});
