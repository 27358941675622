import $ from 'jquery'; // eslint-disable-line no-restricted-imports
import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('.agro_operations_index_page', () => {
  import('./internal/agro_operations_index_page').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.ao-consolidated-report', () => {
  import('./internal/agro-operation-consolidated-report').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.agro-operation-show-page', () => {
  import('./internal/agro-operation-show-page').then(({ default: Component }) => Component());
});

// Other
const selector =
  '.agro_operations_edit_page, ' +
  '.agro-operation-show-page, ' +
  '.agro_tasks_index_page, ' +
  '.agro_operations_index_page, ' +
  '.field_dashboards_agro_operations';
onElelementExistRunOnce(selector, () => {
  $('.link-with-popover[data-toggle="popover"]').popover();
});
