import { set_current_machine_regions_path } from 'js-routes/generated/routes';
import prepareDataByDepthLevels from '../helpers/prepareDataByDepthLevels';

/**
 * Defines the URL to apply the selected folders/groups
 *
 * @param {Set} checkedKeys
 * @param {Map} plainItemsIndex
 * @returns {string}
 */
export default function defineApplyRegionsUrl(checkedKeys, plainItemsIndex) {
  const {
    depthLevels,
    nodesGroupedByDepth,
  } = prepareDataByDepthLevels(checkedKeys, plainItemsIndex);

  const machineRegionsIds = new Set();
  depthLevels.forEach((depth) => {
    nodesGroupedByDepth.get(depth).forEach((node) => {
      machineRegionsIds.add(node.get('id'));
    });
  });

  return set_current_machine_regions_path({
    // eslint-disable-next-line camelcase
    machine_region_ids: [...machineRegionsIds],
  });
}
