import $ from 'jquery'; // eslint-disable-line no-restricted-imports
import { isTest } from 'helpers/userSessionSettings';

// Autohide notices after 2 seconds (during next 3 seconds).
$(() => {
  const fadeTime = {
    true: 3600 * 1000,
    false: 3 * 1000,
  }[isTest];

  const alertElements = $('.alert-info').not('.fc-today');
  setTimeout(() => {
    alertElements
      .fadeTo(2000, 0)
      .slideUp(1000, () => alertElements.remove());
  }, fadeTime);
});
