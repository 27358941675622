import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

import './ajaxSetupDisableCache';
import './redirect';
import './iframe';
import './disableDownloadButtons';
import './hideAlertInfo';
import './linkRememberLocation';

onElelementExistRunOnce('.best_in_place', () => {
  import('./internal/best_in_place').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('#widget_date_switch_from_date, #widget_date_switch_to_date', () => {
  import('./internal/widget_date_switch').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('#date', () => {
  import('./internal/date').then(({ default: Component }) => Component());
});
