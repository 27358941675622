/* eslint-disable no-restricted-syntax */

const mergeURLSearchParams = (...searchParams) => {
  const result = new URLSearchParams('');

  searchParams.forEach((params) => {
    for (const [key, value] of params.entries()) {
      result.append(key, value);
    }
  });

  return result;
};

export default mergeURLSearchParams;
