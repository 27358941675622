/* eslint-disable react/prop-types, react/jsx-props-no-spreading */

import React from 'react';
import { ConfigProvider } from 'antd'; // eslint-disable-line no-restricted-imports
import antdLocale from 'locales/antdLocale';
import { isTest } from 'helpers/userSessionSettings';

const CWOConfigProvider = ({ children, ...props }) => (
  <ConfigProvider
    locale={antdLocale()}
    theme={{
      token: {
        colorPrimary: '#0071cd',
        colorLink: '#0071cd',
        colorLinkHover: '#0092e4',
        colorError: '#cf3537',
        fontFamily: '"Noto Sans VF", "Noto Sans", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
        // make animation faster
        motionUnit: isTest ? 0 : 0.03,
      },
      components: {
        Modal: {
          motionDurationFast: '0s',
          motionDurationMid: '0s',
          motionDurationSlow: '0s',
        },
        // set cropwise button colors
        Button: {
          colorPrimary: '#14803c',
          colorPrimaryHover: '#0c612c',
          colorPrimaryActive: '#0c612c',
          colorPrimaryBg: '#c3ead1',
          colorError: '#cf3537',
          colorErrorHover: '#9c2628',
          colorErrorActive: '#9c2628',
          colorErrorBg: '#ffd9d9',
          colorSuccess: '#14803c',
          colorSuccessHover: '#0c612c',
          colorSuccessActive: '#0c612c',
          colorSuccessBg: '#c3ead1',
        },
      },
    }}
    wave={{ disabled: true }}
    {...props}
  >
    {children}
  </ConfigProvider>
);

export default CWOConfigProvider;
