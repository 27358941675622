import onElelementExistRunOnce from 'helpers/jquery/onElementExistRunOnce';

onElelementExistRunOnce('.maintenance_machines_table', () => {
  import('./internal/maintenance_machines_table').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.maintenance_implements_table', () => {
  import('./internal/maintenance_implements_table').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('.maintenance-plans-report-table', () => {
  import('./internal/maintenance-plans-report-table').then(({ default: Component }) => Component());
});

onElelementExistRunOnce('#show_additional_block', () => {
  import('./internal/show_additional_block').then(({ default: Component }) => Component());
});
