export default function recursivlyModifyTree({
  tree, callback, nestingKey = 'children',
}) {
  [tree]
    .flat()
    .forEach((item) => {
      if (callback instanceof Function) {
        callback(item);
      }

      if (!Array.isArray(item[nestingKey])) return;

      item[nestingKey].forEach((children) => {
        recursivlyModifyTree({
          tree: children,
          callback,
          nestingKey,
        });
      });
    });
}
