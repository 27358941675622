/* eslint-disable */

import $ from 'jquery';

(function(){
  const bootstrap_enabled = (typeof $().modal == 'function');

  if (bootstrap_enabled) {
    // https://github.com/dangrossman/daterangepicker/issues/156#issuecomment-173679152
    $.fn.modal.Constructor.prototype.enforceFocus = function () {};
  }
})();

